// src/pages/TermsOfService.js
import React, { useEffect } from "react";
import BannerAd from "../components/BannerAd.js";

const TermsOfService = () => {
  useEffect(() => {
    // Update the document title
    document.title = "Terms of Service - AfterCreds";

    // Handle meta description
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      "Review the terms of service for using AfterCreds, including consent to data usage.";

    // Handle Open Graph title
    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      ogTitle = document.createElement("meta");
      ogTitle.setAttribute("property", "og:title");
      document.head.appendChild(ogTitle);
    }
    ogTitle.content = "Terms of Service - AfterCreds";

    // Handle Open Graph description
    let ogDescription = document.querySelector(
      'meta[property="og:description"]'
    );
    if (!ogDescription) {
      ogDescription = document.createElement("meta");
      ogDescription.setAttribute("property", "og:description");
      document.head.appendChild(ogDescription);
    }
    ogDescription.content =
      "Understand the terms of service for AfterCreds, including your rights and obligations.";

    // Handle Open Graph URL
    let ogUrl = document.querySelector('meta[property="og:url"]');
    if (!ogUrl) {
      ogUrl = document.createElement("meta");
      ogUrl.setAttribute("property", "og:url");
      document.head.appendChild(ogUrl);
    }
    ogUrl.content = "https://www.aftercreds.com/terms-of-service";

    return () => {
      // Reset metadata to default values on unmount
      if (metaDescription) {
        metaDescription.content =
          "A movie website where users can browse their favorite movies and see if they have post-credit scenes.";
      }
      if (ogTitle) {
        ogTitle.content = "AfterCreds - Movie Database";
      }
      if (ogDescription) {
        ogDescription.content =
          "Browse and view the latest movies for information on post-credit scenes.";
      }
      if (ogUrl) {
        ogUrl.content = "https://www.aftercreds.com";
      }
    };
  }, []);

  return (
    <div className="font-custom min-h-screen bg-gray-200 dark:bg-gray-900 pt-20 pb-20 sm:pb-0">
      <div className="container mx-auto p-8">
        <h1 className="text-3xl font-semibold mb-6 text-gray-900 dark:text-white">
          Terms of Service
        </h1>
        <div className="bg-white dark:bg-gray-800 rounded-lg border border-gray-300 dark:border-gray-600 shadow-lg overflow-hidden w-full">
          <div className="sm:pl-8 sm:p-4 p-4">
            <p className="text-gray-700 dark:text-gray-300 mb-6">
              Last Updated: 10/13/2024
            </p>
            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              1. Consent to Data Usage
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              By using AfterCreds, you consent to the collection of data through
              cookies and third-party services like Google Analytics and Google
              AdSense. If you do not agree to this, please discontinue use of
              the platform.
            </p>

            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              2. Account Information and Responsibilities
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              You are responsible for maintaining the confidentiality of your
              account and password. You must notify us immediately if your
              account is compromised. Any activity conducted through your
              account will be your responsibility.
            </p>

            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              3. User Conduct
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              Users are expected to act responsibly while using the platform,
              avoiding any illegal activity, misuse of features, or tampering
              with the platform or data. Spamming, hacking, and other misuse of
              the service will result in termination of your account.
            </p>

            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              4. Intellectual Property Rights
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              All content and data, including movie information and after-credit
              scenes, are owned by AfterCreds or third-party services (e.g.,
              TMDB API). You are prohibited from copying, distributing, or using
              content without prior permission.
            </p>

            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              5. Termination of Accounts
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              We reserve the right to terminate your account if you violate
              these terms, misuse the platform, or engage in any behavior that
              threatens the integrity of AfterCreds. Upon termination, your
              personal data and favorites will be deleted.
            </p>

            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              6. Limitations of Liability
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              AfterCreds is provided "as is" without warranties of any kind. We
              do not guarantee the accuracy of movie data or the availability of
              the platform. We are not liable for any damages arising from the
              use of the platform.
            </p>

            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              7. Governing Law
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              These terms will be governed by and construed in accordance with
              the laws of the United States.
            </p>

            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              8. U.S. vs E.U. User Data Consent
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              For U.S. users, by using AfterCreds, you consent to the collection
              of data via cookies, Google Analytics, and Google AdSense ads
              automatically. E.U. users are required to give explicit consent
              through the cookie consent banner for such tracking and
              advertising features.
            </p>

            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              9. Changes to Terms
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              We reserve the right to update these terms at any time. Changes
              will be communicated through the platform and take effect upon
              posting.
            </p>
          </div>
        </div>
      </div>

      <BannerAd />
    </div>
  );
};

export default TermsOfService;
