import React, { useEffect } from "react";
import BannerAd from "../components/BannerAd.js";

const PrivacyPolicy = () => {
  useEffect(() => {
    // Update the document title
    document.title = "Privacy Policy - AfterCreds";

    // Handle meta description
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      "Learn about the privacy policy for AfterCreds, including how your data is collected, stored, and used.";

    // Handle Open Graph title
    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      ogTitle = document.createElement("meta");
      ogTitle.setAttribute("property", "og:title");
      document.head.appendChild(ogTitle);
    }
    ogTitle.content = "Privacy Policy - AfterCreds";

    // Handle Open Graph description
    let ogDescription = document.querySelector(
      'meta[property="og:description"]'
    );
    if (!ogDescription) {
      ogDescription = document.createElement("meta");
      ogDescription.setAttribute("property", "og:description");
      document.head.appendChild(ogDescription);
    }
    ogDescription.content =
      "Understand how AfterCreds handles your data, ensures your privacy, and complies with regulations.";

    // Handle Open Graph URL
    let ogUrl = document.querySelector('meta[property="og:url"]');
    if (!ogUrl) {
      ogUrl = document.createElement("meta");
      ogUrl.setAttribute("property", "og:url");
      document.head.appendChild(ogUrl);
    }
    ogUrl.content = "https://www.aftercreds.com/privacy-policy";

    return () => {
      // Reset metadata to default values on unmount
      if (metaDescription) {
        metaDescription.content =
          "A movie website where users can browse their favorite movies and see if they have post-credit scenes.";
      }
      if (ogTitle) {
        ogTitle.content = "AfterCreds - Movie Database";
      }
      if (ogDescription) {
        ogDescription.content =
          "Browse and view the latest movies for information on post-credit scenes.";
      }
      if (ogUrl) {
        ogUrl.content = "https://www.aftercreds.com";
      }
    };
  }, []);

  return (
    <div className="font-custom bg-gray-200 dark:bg-gray-900 min-h-screen pt-20 pb-20 sm:pb-0">
      <div className="container mx-auto p-8">
        <h1 className="text-3xl font-semibold mb-6 text-gray-900 dark:text-white">
          Privacy Policy
        </h1>
        <div className="bg-white dark:bg-gray-800 rounded-lg border border-gray-300 dark:border-gray-600 shadow-lg overflow-hidden w-full">
          <div className="sm:pl-8 sm:p-4 p-4">
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              Last Updated: 10/13/2024
            </p>
            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              1. Information We Collect
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              Account Information: When you create an account, we collect your
              email address and username.
              <br />
              Usage Data: We collect data on how you interact with the platform
              (e.g., favorite movies, search queries).
              <br />
              Analytics Data: We use Google Analytics to track visitor behavior,
              such as page views and traffic patterns.
              <br />
              Cookies: We use cookies for functionality and tracking, including
              Google AdSense.
              <br />
              Firebase Data: We use Firebase for account authentication and
              storing your personal preferences like favorite movies.
            </p>
            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              2. Use of Information
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              We use the collected data to:
              <ul className="list-disc ml-6">
                <li>Provide personalized experiences.</li>
                <li>Analyze user interactions via Google Analytics.</li>
                <li>Display relevant ads through Google AdSense.</li>
              </ul>
            </p>
            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              3. Sharing of Information
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              We do not sell or share your personal information, except with
              third-party services (Google Analytics, Firebase, etc.).
            </p>
            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              4. Cookies and Tracking
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              We use cookies for login sessions and ad tracking. You can manage
              cookies through your browser.
            </p>
            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              5. Data Protection
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              We implement security measures to protect your data and ensure its
              confidentiality.
            </p>
            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              6. User Rights
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              You may update or delete your account and personal information via
              your account settings.
            </p>
            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              7. Third-Party Services
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              We use services like Google Analytics, Google AdSense, Firebase,
              and TMDB API, each with its own privacy policy.
            </p>
            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              8. Changes to This Privacy Policy
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              We may update this policy. Changes will be posted on this page.
            </p>
            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              9. U.S. vs E.U. User Consent
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              For U.S. users, by using AfterCreds, you automatically consent to
              the use of cookies, Google Analytics tracking, and Google AdSense
              ads. For E.U. users, consent is explicitly required before
              cookies, tracking, or ads are enabled. If you are a U.S. user and
              do not agree, you may discontinue use of the website.
            </p>
            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              10. Contact Us
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              For questions, contact us at aftercreds@gmail.com.
            </p>
          </div>
        </div>
      </div>
      <BannerAd />
    </div>
  );
};

export default PrivacyPolicy;
