import { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  getUserPreferences,
  saveMovieToFavorites,
  removeMovieFromFavorites,
} from "../utils/auth.js"; // Import auth functions
import { auth } from "../utils/firebase.js";
import { HeartIcon as HeartIconSolid } from "@heroicons/react/24/solid"; // Import solid heart icon
import { HeartIcon as HeartIconOutline } from "@heroicons/react/24/outline"; // Import outline heart icon
import BannerAd from "../components/BannerAd.js";

const FavoritesPage = () => {
  const API_KEY = process.env.REACT_APP_TMDB_API_KEY; // Access the API key from environment variables
  const [movies, setMovies] = useState([]);
  const [likedMovies, setLikedMovies] = useState([]); // Ensure likedMovies is always an array
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Update the document title
    document.title = "Favorites - AfterCreds";

    // Handle meta description
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      "View and manage your favorite movies on AfterCreds.";

    // Handle Open Graph title
    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      ogTitle = document.createElement("meta");
      ogTitle.setAttribute("property", "og:title");
      document.head.appendChild(ogTitle);
    }
    ogTitle.content = "Favorites - AfterCreds";

    // Handle Open Graph description
    let ogDescription = document.querySelector(
      'meta[property="og:description"]'
    );
    if (!ogDescription) {
      ogDescription = document.createElement("meta");
      ogDescription.setAttribute("property", "og:description");
      document.head.appendChild(ogDescription);
    }
    ogDescription.content =
      "View and manage your favorite movies on AfterCreds.";

    // Handle Open Graph URL
    let ogUrl = document.querySelector('meta[property="og:url"]');
    if (!ogUrl) {
      ogUrl = document.createElement("meta");
      ogUrl.setAttribute("property", "og:url");
      document.head.appendChild(ogUrl);
    }
    ogUrl.content = "https://www.aftercreds.com/favorites";

    // Inject structured data for SEO purposes
    if (movies.length > 0) {
      const structuredData = {
        "@context": "https://schema.org",
        "@type": "MovieCollection",
        name: "Favorite Movies",
        movie: movies.map((movie) => ({
          "@type": "Movie",
          name: movie.title,
          url: `https://www.aftercreds.com/movie/${movie.title
            .replace(/\s+/g, "-")
            .replace(/[^\w-]/g, "")}/${movie.id}`,
          image: `https://image.tmdb.org/t/p/w500/${movie.poster_path}`,
          datePublished: movie.release_date,
          description: movie.overview,
        })),
      };

      const scriptTag = document.createElement("script");
      scriptTag.type = "application/ld+json";
      scriptTag.textContent = JSON.stringify(structuredData);
      document.head.appendChild(scriptTag);

      return () => {
        // Clean up metadata and structured data on unmount or movie change
        document.title = "AfterCreds - Movie Database";
        metaDescription.content =
          "A movie website where users can browse their favorite movies and see if they have post-credit scenes.";
        ogTitle.content = "AfterCreds - Movie Database";
        ogDescription.content =
          "Browse and view the latest movies for information on post-credit scenes.";
        ogUrl.content = "https://www.aftercreds.com";

        document.head.removeChild(scriptTag);
      };
    }
  }, [movies]); // Runs whenever the `movies` array changes

  // Fetch user's favorite movies from Firestore
  const fetchFavorites = useCallback(
    async (user) => {
      const fetchMoviesWithCertifications = async (moviesArray) => {
        const moviesWithCertifications = await Promise.all(
          moviesArray.map(async (movie) => {
            try {
              const releaseDatesResponse = await fetch(
                `https://api.themoviedb.org/3/movie/${movie.id}/release_dates?api_key=${API_KEY}`
              );
              const releaseDatesData = await releaseDatesResponse.json();

              const usRelease = releaseDatesData.results.find(
                (release) => release.iso_3166_1 === "US"
              );
              const certification =
                usRelease?.release_dates.find((release) => release.type === 3)
                  ?.certification || "NR";

              return { ...movie, certification };
            } catch (error) {
              console.error(
                `Error fetching certification for movie ${movie.id}:`,
                error
              );
              return { ...movie, certification: "NR" };
            }
          })
        );
        return moviesWithCertifications;
      };

      setLoading(true);
      try {
        if (user) {
          const preferences = await getUserPreferences(user.email); // Use email instead of uid
          const likedMoviesFromDB = Array.isArray(preferences?.likedMovies)
            ? preferences.likedMovies
            : [];
          setLikedMovies(likedMoviesFromDB);

          // If there are liked movies, fetch their details from the API
          if (likedMoviesFromDB.length > 0) {
            const movieDetailsPromises = likedMoviesFromDB.map((movieId) =>
              fetch(
                `https://api.themoviedb.org/3/movie/${movieId}?api_key=${API_KEY}`
              ).then((response) => response.json())
            );
            const movieDetails = await Promise.all(movieDetailsPromises);

            // Fetch certifications after movie details
            const moviesWithCertifications =
              await fetchMoviesWithCertifications(
                movieDetails.filter((movie) => movie !== null)
              );
            setMovies(moviesWithCertifications);
          } else {
            setMovies([]);
          }
        }
      } catch (error) {
        console.error("Error fetching favorites: ", error);
      }
      setLoading(false);
    },
    [API_KEY]
  );

  // Handle authentication state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchFavorites(user); // Fetch favorites when logged in
      } else {
        // Reset states when user logs out
        setLikedMovies([]);
        setMovies([]);
        setLoading(false);
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [fetchFavorites]);

  // Handle toggling favorite movies (add/remove)
  const handleToggleFavorite = async (movie) => {
    const user = auth.currentUser;
    if (!user || !movie.id) return;

    if (likedMovies.includes(movie.id)) {
      // If movie is liked, remove from favorites
      const updatedLikedMovies = likedMovies.filter((id) => id !== movie.id);
      setLikedMovies(updatedLikedMovies);
      await removeMovieFromFavorites(user.email, movie.id); // Use email here
    } else {
      // If movie is not liked, add to favorites
      const updatedLikedMovies = [...likedMovies, movie.id];
      setLikedMovies(updatedLikedMovies);
      await saveMovieToFavorites(user.email, movie.id); // Use email here
    }
  };

  // Utility function to format date as mm-dd-yyyy
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getUTCDate()).padStart(2, "0");
    return `${month}/${day}/${year}`;
  };

  return (
    <div className="font-custom min-h-screen bg-gray-200 dark:bg-gray-900 pt-20 pb-20 sm:pb-0">
      <div className="container mx-auto p-8">
        <h1 className="text-3xl font-semibold mb-6 text-gray-900 dark:text-white">
          Favorites
        </h1>

        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6">
          {loading
            ? // Display placeholder cards when loading
              Array(likedMovies.length || 10)
                .fill(0)
                .map((_, index) => (
                  <div key={index} className="animate-pulse">
                    <div className="bg-gray-300 dark:bg-gray-700 h-64 rounded-lg"></div>
                    <div className="mt-2 h-4 bg-gray-300 dark:bg-gray-700 rounded w-3/4"></div>
                    <div className="mt-1 h-4 bg-gray-300 dark:bg-gray-700 rounded w-1/2"></div>
                  </div>
                ))
            : // Display actual favorite movie cards when data is loaded
              movies.map(
                (movie) =>
                  movie.poster_path && (
                    <div key={movie.id} className="relative">
                      <Link
                        to={`/movie/${movie.title
                          .replace(/\s+/g, "-")
                          .replace(/[^\w-]/g, "")}/${movie.id}`}
                      >
                        <div className="bg-white dark:bg-gray-700 rounded-lg shadow-md overflow-hidden transform hover:scale-105 hover:border-green-400 hover:border-2 transition duration-300 ease-in-out">
                          <img
                            src={`https://image.tmdb.org/t/p/w500/${movie.poster_path}`}
                            alt={movie.title}
                            className="w-full h-full object-cover"
                          />
                          <div className="p-4">
                            <h3 className="text-md font-medium text-gray-800 dark:text-gray-200">
                              {movie.title.length > 23
                                ? `${movie.title.substring(0, 23)}...`
                                : movie.title}
                            </h3>
                            <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                              {movie.release_date
                                ? formatDate(movie.release_date)
                                : "N/A"}
                              {movie.release_date && movie.certification && (
                                <span> &bull; {movie.certification}</span>
                              )}
                            </p>
                          </div>
                        </div>
                      </Link>

                      {/* Like/Unlike Button */}
                      <button
                        className="absolute top-2 right-2 focus:outline-none"
                        onClick={() => handleToggleFavorite(movie)}
                      >
                        {likedMovies.includes(movie.id) ? (
                          <HeartIconSolid className="w-10 h-10 text-red-500" />
                        ) : (
                          <HeartIconOutline className="w-10 h-10 text-gray-300 dark:text-gray-500" />
                        )}
                      </button>
                    </div>
                  )
              )}

          {!loading && movies.length === 0 && (
            <p className="text-center text-gray-700 dark:text-gray-300 w-full col-span-5">
              You have no favorite movies saved.
            </p>
          )}
        </div>
        <BannerAd />
      </div>
    </div>
  );
};

export default FavoritesPage;
