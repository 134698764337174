import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchMovieDetails } from "../utils/api.js";
import {
  QuestionMarkCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { HeartIcon as HeartIconSolid } from "@heroicons/react/24/solid";
import { HeartIcon as HeartIconOutline } from "@heroicons/react/24/outline";
import { auth } from "../utils/firebase.js";
import {
  saveMovieToFavorites,
  removeMovieFromFavorites,
  getUserFavorites,
  checkIfAdmin,
  saveAfterCreditsInfo,
} from "../utils/auth.js";
import { doc, getDoc } from "firebase/firestore"; // Import Firestore methods
import { db } from "../utils/firebase.js"; // Firestore database instance
import BannerAd from "../components/BannerAd.js";

const MoviePage = () => {
  const { movieId } = useParams();
  const [movie, setMovie] = useState(null);
  const [likedMovies, setLikedMovies] = useState([]); // User's liked movies
  const user = auth.currentUser; // Get current user
  const [isAdmin, setIsAdmin] = useState(false); // Track admin status
  const [afterCreditScene1, setAfterCreditScene1] = useState("unknown"); // 1st scene status
  const [afterCreditScene2, setAfterCreditScene2] = useState("unknown"); // 2nd scene status
  const [afterCreditInfoText, setAfterCreditInfoText] = useState("");
  const movieReleaseDate = movie?.usReleaseDate
    ? (() => {
        const date = new Date(movie.usReleaseDate);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
        const day = String(date.getUTCDate()).padStart(2, "0");
        return `${month}/${day}/${year}`;
      })()
    : "Unknown";

  useEffect(() => {
    const fetchMovie = async () => {
      try {
        if (!movieId) {
          console.error("Movie ID is missing from URL.");
          return;
        }

        // Fetch main movie details
        const movieDetails = await fetchMovieDetails(movieId);
        const movieData = movieDetails.data;

        // Fetch release dates by region (including US)
        const releaseDatesResponse = await fetch(
          `https://api.themoviedb.org/3/movie/${movieId}/release_dates?api_key=${process.env.REACT_APP_TMDB_API_KEY}`
        );
        const releaseDatesData = await releaseDatesResponse.json();

        const usRelease = releaseDatesData.results.find(
          (release) => release.iso_3166_1 === "US"
        );

        let correctReleaseDate = usRelease?.release_dates.find(
          (release) => release.type === 2
        )?.release_date;

        if (!correctReleaseDate) {
          correctReleaseDate = usRelease?.release_dates.find(
            (release) => release.type === 3
          )?.release_date;
        }

        if (!correctReleaseDate) {
          correctReleaseDate = usRelease?.release_dates[0]?.release_date;
        }

        const certification =
          usRelease?.release_dates.find((release) => release.type === 3)
            ?.certification || "Unrated"; // Fetch certification or default to Unrated

        const updatedMovieData = {
          ...movieData,
          usReleaseDate: correctReleaseDate || movieData.release_date,
          certification,
        };

        setMovie(updatedMovieData);

        // Fetch After-Credit Scenes Data from Firestore
        const docRef = doc(db, "movies", movieId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setAfterCreditScene1(data.afterCreditScene1 || "unknown");
          setAfterCreditScene2(data.afterCreditScene2 || "unknown");

          // Calculate after-credit scenes
          const sceneCount =
            (data.afterCreditScene1 === "yes" ? 1 : 0) +
            (data.afterCreditScene2 === "yes" ? 1 : 0);

          const sceneColor = sceneCount > 0 ? "text-green-600" : "text-red-600";

          setAfterCreditInfoText(
            <span>
              {movieData.title} has{" "}
              <span className={`text-3xl font-bold ${sceneColor}`}>
                {sceneCount}
              </span>{" "}
              after-credits scene{sceneCount !== 1 ? "s" : ""}.
            </span>
          );
        }

        // Fetch liked movies for the current user and check if this movie is liked
        if (user) {
          const likedMoviesData = await getUserFavorites(user.email);
          setLikedMovies(likedMoviesData || []); // Set liked movies here

          const isAdminUser = await checkIfAdmin(user.email);
          setIsAdmin(isAdminUser);
        }
      } catch (error) {
        console.error("Error fetching movie details:", error);
      }
    };

    fetchMovie();
  }, [movieId, user]);

  useEffect(() => {
    if (!movie) return;

    // Set the document title
    document.title = `${movie.title} - AfterCreds`;

    // Set canonical URL
    let canonicalTag = document.querySelector('link[rel="canonical"]');
    if (!canonicalTag) {
      canonicalTag = document.createElement("link");
      canonicalTag.setAttribute("rel", "canonical");
      document.head.appendChild(canonicalTag);
    }
    canonicalTag.setAttribute(
      "href",
      `https://www.aftercreds.com/movie/${movie.title
        .replace(/\s+/g, "-")
        .replace(/[^\w-]/g, "")}/${movie.id}`
    );

    // Handle meta description
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      movie.overview || "View details about this movie.";

    // Handle Open Graph title
    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      ogTitle = document.createElement("meta");
      ogTitle.setAttribute("property", "og:title");
      document.head.appendChild(ogTitle);
    }
    ogTitle.content = `${movie.title} - AfterCreds`;

    // Handle Open Graph description
    let ogDescription = document.querySelector(
      'meta[property="og:description"]'
    );
    if (!ogDescription) {
      ogDescription = document.createElement("meta");
      ogDescription.setAttribute("property", "og:description");
      document.head.appendChild(ogDescription);
    }
    ogDescription.content =
      movie.overview || "Discover details about this movie.";

    // Handle Open Graph URL
    let ogUrl = document.querySelector('meta[property="og:url"]');
    if (!ogUrl) {
      ogUrl = document.createElement("meta");
      ogUrl.setAttribute("property", "og:url");
      document.head.appendChild(ogUrl);
    }
    ogUrl.content = `https://www.aftercreds.com/movie/${movie.title
      .replace(/\s+/g, "-")
      .replace(/[^\w-]/g, "")}/${movie.id}`;

    // Handle Open Graph image
    let ogImage = document.querySelector('meta[property="og:image"]');
    if (!ogImage) {
      ogImage = document.createElement("meta");
      ogImage.setAttribute("property", "og:image");
      document.head.appendChild(ogImage);
    }
    ogImage.content = `https://image.tmdb.org/t/p/w500${movie.poster_path}`;

    // Inject structured data for SEO purposes
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "Movie",
      name: movie.title,
      image: `https://image.tmdb.org/t/p/w500${movie.poster_path}`,
      datePublished: movie.release_date,
      duration: `PT${movie.runtime}M`,
      description: movie.overview,
    };

    // Only add aggregateRating if ratingValue and ratingCount are valid
    if (movie.vote_average > 0 && movie.vote_count > 0) {
      structuredData.aggregateRating = {
        "@type": "AggregateRating",
        ratingValue: movie.vote_average,
        ratingCount: movie.vote_count,
        bestRating: 10,
        worstRating: 0,
      };
    }

    const scriptTag = document.createElement("script");
    scriptTag.type = "application/ld+json";
    scriptTag.text = JSON.stringify(structuredData);
    document.head.appendChild(scriptTag);

    return () => {
      // Reset metadata to default values
      document.title = "AfterCreds - Movie Database";

      if (metaDescription) {
        metaDescription.content =
          "A movie website where users can browse their favorite movies and see if they have post-credit scenes.";
      }
      if (ogTitle) {
        ogTitle.content = "AfterCreds - Movie Database";
      }
      if (ogDescription) {
        ogDescription.content =
          "Browse and view the latest movies for information on post-credit scenes.";
      }
      if (ogUrl) {
        ogUrl.content = "https://www.aftercreds.com";
      }
      if (ogImage) {
        ogImage.content = "https://www.aftercreds.com/logo512.png"; // Default image
      }

      if (scriptTag) {
        document.head.removeChild(scriptTag);
      }
    };
  }, [movie]);

  // Handle like/unlike the movie
  const handleLike = async () => {
    if (!user) {
      alert("You need to be logged in to like a movie.");
      return;
    }

    if (likedMovies.includes(movie.id)) {
      await removeMovieFromFavorites(user.email, movie.id);
      setLikedMovies(likedMovies.filter((id) => id !== movie.id));
    } else {
      await saveMovieToFavorites(user.email, movie.id);
      setLikedMovies([...likedMovies, movie.id]);
    }
  };

  // Function to handle saving after-credits data to Firestore
  const handleSave = async () => {
    try {
      // Call the centralized save function from auth.js
      await saveAfterCreditsInfo(
        movieId, // Pass the movie ID
        movie.title, // Pass the movie title
        afterCreditScene1, // Pass the first after-credit scene status
        afterCreditScene2 // Pass the second after-credit scene status
      );

      // Re-fetch the updated data to update the UI
      const docRef = doc(db, "movies", movieId);
      const updatedDoc = await getDoc(docRef);
      if (updatedDoc.exists()) {
        const data = updatedDoc.data();
        setAfterCreditScene1(data.afterCreditScene1 || "unknown");
        setAfterCreditScene2(data.afterCreditScene2 || "unknown");
      }

      alert("Changes saved successfully!");
    } catch (error) {
      console.error("Error saving after-credits data:", error);
    }
  };

  return (
    movie && (
      <div className="font-custom flex justify-center items-center min-h-screen bg-gray-200 dark:bg-gray-900 pb-20 sm:pb-0">
        {/* Movie Details Card */}
        <div className="bg-white dark:bg-gray-800 rounded-lg sm:border border-gray-300 dark:border-gray-600 shadow-lg overflow-hidden max-w-6xl w-full">
          <div className="flex flex-col sm:flex-row sm:items-start">
            {/* Movie Poster */}
            <div className="w-full sm:w-1/2 lg:w-1/3 mt-[74px] sm:mt-0 sm:mb-0 relative">
              <img
                src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                alt={`Poster of the movie ${movie.title}`}
                className="object-cover w-full h-auto sm:h-full rounded-t-lg sm:rounded-lg"
              />

              {/* Like Button */}
              <button
                className="absolute top-4 right-4 focus:outline-none"
                onClick={handleLike}
              >
                {likedMovies.includes(movie.id) ? (
                  <HeartIconSolid className="w-10 h-10 text-red-500" />
                ) : (
                  <HeartIconOutline className="w-10 h-10 text-gray-300 dark:text-gray-500" />
                )}
              </button>

              {/* End Credit Scenes Section for Mobile (placed directly under the poster) */}
              <div className="sm:hidden bg-gray-300 dark:bg-gray-700 flex justify-around items-center w-full rounded-b-lg">
                <p className="text-center text-lg  font-semibold text-gray-800 dark:text-gray-200 w-1/3">
                  Credit Scenes:
                </p>
                <div
                  className={`text-center w-1/3 py-4 whitespace-nowrap ${
                    afterCreditScene1 === "yes"
                      ? "bg-green-700"
                      : afterCreditScene1 === "no"
                      ? "bg-red-700"
                      : "bg-gray-600"
                  }`}
                >
                  <p
                    className={`text-xl  font-semibold ${
                      afterCreditScene1 === "yes"
                        ? "text-green-100"
                        : afterCreditScene1 === "no"
                        ? "text-red-100"
                        : "text-gray-100"
                    }`}
                  >
                    Mid Scene
                  </p>
                  {afterCreditScene1 === "yes" && (
                    <CheckCircleIcon className="w-6 h-6 text-green-100 mx-auto" />
                  )}
                  {afterCreditScene1 === "no" && (
                    <XCircleIcon className="w-6 h-6 text-red-100 mx-auto" />
                  )}
                  {afterCreditScene1 === "unknown" && (
                    <QuestionMarkCircleIcon className="w-6 h-6 text-gray-500 mx-auto" />
                  )}
                  {isAdmin && (
                    <select
                      value={afterCreditScene1}
                      onChange={(e) => setAfterCreditScene1(e.target.value)}
                      className="mt-2 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 p-2 rounded"
                    >
                      <option value="unknown">Unknown</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  )}
                </div>

                <div
                  className={`text-center w-1/3 py-4 rounded-br-lg whitespace-nowrap ${
                    afterCreditScene2 === "yes"
                      ? "bg-green-700"
                      : afterCreditScene2 === "no"
                      ? "bg-red-700"
                      : "bg-gray-600"
                  }`}
                >
                  <p
                    className={`text-xl  font-semibold ${
                      afterCreditScene2 === "yes"
                        ? "text-green-100"
                        : afterCreditScene2 === "no"
                        ? "text-red-100"
                        : "text-gray-100"
                    }`}
                  >
                    End Scene
                  </p>
                  {afterCreditScene2 === "yes" && (
                    <CheckCircleIcon className="w-6 h-6 text-green-100 mx-auto" />
                  )}
                  {afterCreditScene2 === "no" && (
                    <XCircleIcon className="w-6 h-6 text-red-100 mx-auto" />
                  )}
                  {afterCreditScene2 === "unknown" && (
                    <QuestionMarkCircleIcon className="w-6 h-6 text-gray-500 mx-auto" />
                  )}
                  {isAdmin && (
                    <select
                      value={afterCreditScene2}
                      onChange={(e) => setAfterCreditScene2(e.target.value)}
                      className="mt-2 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 p-2 rounded"
                    >
                      <option value="unknown">Unknown</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  )}
                </div>
              </div>

              {/* Save Button for Mobile */}
              {isAdmin && (
                <div className="mt-4 flex justify-center sm:hidden">
                  <button
                    onClick={handleSave}
                    className="py-2 px-6 rounded-lg
    text-white text-lg font-semibold
    bg-gradient-to-r from-green-600 to-green-500
    hover:from-green-500 hover:to-green-400
    active:from-green-700 active:to-green-600
    transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95
    shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-green-300"
                  >
                    Save Changes
                  </button>
                </div>
              )}

              {/* Mobile: Movie Title */}
              <h1 className="text-center sm:hidden block font-semibold text-3xl lg:text-4xl font-custom text-gray-800 dark:text-gray-200 m-4">
                {movie.title}
              </h1>

              {/* Date and Length for Mobile (under the end scenes) */}
              <div className="block sm:hidden text-center text-sm text-gray-500 dark:text-gray-400 mt-4">
                {movieReleaseDate} <span className="mx-2">•</span>{" "}
                {movie.runtime} min
                {movie.certification && movie.certification !== "Unrated" && (
                  <>
                    <span className="mx-2">•</span> {movie.certification}
                  </>
                )}
              </div>
            </div>

            {/* Movie Information */}
            <div className="sm:w-1/2 lg:w-2/3 sm:pl-8 sm:p-4 p-4">
              {/* Desktop: Date and Length on the Top-Right */}
              <div className="hidden sm:flex justify-end text-sm text-gray-500 dark:text-gray-400 mb-4">
                {movieReleaseDate} <span className="mx-2">•</span>{" "}
                {movie.runtime} min
                {movie.certification && movie.certification !== "Unrated" && (
                  <>
                    <span className="mx-2">•</span> {movie.certification}
                  </>
                )}
              </div>

              {/* Desktop: Movie Title */}
              <h1 className="hidden sm:block font-semibold text-3xl lg:text-4xl text-gray-800 dark:text-gray-200 mb-4">
                {movie.title}
              </h1>

              {/* Movie Overview */}
              <p className="text-gray-700 dark:text-gray-300 mb-6">
                {movie.overview}
              </p>

              <div className="mt-8">
                <hr className="hidden sm:block border-gray-300 dark:border-gray-700" />
              </div>

              {/* End Credit Scenes Section for Desktop */}
              <div className="hidden sm:flex justify-around items-center my-8 rounded-lg bg-gray-300 dark:bg-gray-700">
                <p className="font-semibold text-xl font-bold text-gray-800 dark:text-gray-200 flex-1 text-center">
                  After Credit Scenes:
                </p>
                <div
                  className={`text-center flex-1 p-4 ${
                    afterCreditScene1 === "yes"
                      ? "bg-green-700"
                      : afterCreditScene1 === "no"
                      ? "bg-red-700"
                      : "bg-gray-600"
                  }`}
                >
                  <p
                    className={`text-3xl font-semibold ${
                      afterCreditScene1 === "yes"
                        ? "text-green-100"
                        : afterCreditScene1 === "no"
                        ? "text-red-100"
                        : "text-gray-100"
                    }`}
                  >
                    Mid Scene
                  </p>
                  {afterCreditScene1 === "yes" && (
                    <CheckCircleIcon className="w-6 h-6 text-green-100 mx-auto" />
                  )}
                  {afterCreditScene1 === "no" && (
                    <XCircleIcon className="w-6 h-6 text-red-100 mx-auto" />
                  )}
                  {afterCreditScene1 === "unknown" && (
                    <QuestionMarkCircleIcon className="w-6 h-6 text-gray-500 mx-auto" />
                  )}
                  {isAdmin && (
                    <select
                      value={afterCreditScene1}
                      onChange={(e) => setAfterCreditScene1(e.target.value)}
                      className="mt-2 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 p-2 rounded"
                    >
                      <option value="unknown">Unknown</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  )}
                </div>
                <div
                  className={`text-center flex-1 p-4 rounded-r-lg ${
                    afterCreditScene2 === "yes"
                      ? "bg-green-700"
                      : afterCreditScene2 === "no"
                      ? "bg-red-700"
                      : "bg-gray-600"
                  }`}
                >
                  <p
                    className={`text-3xl font-semibold ${
                      afterCreditScene2 === "yes"
                        ? "text-green-100"
                        : afterCreditScene2 === "no"
                        ? "text-red-100"
                        : "text-gray-100"
                    }`}
                  >
                    End Scene
                  </p>
                  {afterCreditScene2 === "yes" && (
                    <CheckCircleIcon className="w-6 h-6 text-green-100 mx-auto" />
                  )}
                  {afterCreditScene2 === "no" && (
                    <XCircleIcon className="w-6 h-6 text-red-100 mx-auto" />
                  )}
                  {afterCreditScene2 === "unknown" && (
                    <QuestionMarkCircleIcon className="w-6 h-6 text-gray-500 mx-auto" />
                  )}
                  {isAdmin && (
                    <select
                      value={afterCreditScene2}
                      onChange={(e) => setAfterCreditScene2(e.target.value)}
                      className="mt-2 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 p-2 rounded"
                    >
                      <option value="unknown">Unknown</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  )}
                </div>
              </div>

              {afterCreditInfoText && (
                <div>
                  {/* After-Credit Info Banner - Mobile */}
                  <div className="sm:hidden flex justify-center bg-gray-200 dark:bg-gray-700 rounded-lg p-4 my-5">
                    <p className="text-center text-lg text-gray-700 dark:text-gray-300 flex items-center">
                      {afterCreditInfoText}
                    </p>
                  </div>

                  {/* After-Credit Info Banner - Desktop */}
                  <div className="hidden sm:flex justify-center bg-gray-200 dark:bg-gray-700 rounded-lg p-4 my-4">
                    <p className="text-center text-lg text-gray-700 dark:text-gray-300 flex items-center">
                      {afterCreditInfoText}
                    </p>
                  </div>
                </div>
              )}

              {/* Save Button for Desktop */}
              {isAdmin && (
                <div className="mt-4 flex justify-center hidden sm:flex">
                  <button
                    onClick={handleSave}
                    className="py-2 px-6 rounded-lg
    text-white text-lg font-semibold
    bg-gradient-to-r from-green-600 to-green-500
    hover:from-green-500 hover:to-green-400
    active:from-green-700 active:to-green-600
    transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95
    shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-green-300"
                  >
                    Save Changes
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <BannerAd />
      </div>
    )
  );
};

export default MoviePage;
