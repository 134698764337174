import React, { useState, useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore"; // Use onSnapshot instead of getDoc
import { db } from "../utils/firebase.js";

const BannerAd = () => {
  const [adsEnabled, setAdsEnabled] = useState(false);
  const [adsLoaded, setAdsLoaded] = useState(false);
  const [isUSUser, setIsUSUser] = useState(false);
  const [showAds, setShowAds] = useState(false);

  useEffect(() => {
    // Firestore real-time listener
    const docRef = doc(db, "settings", "ads");
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        setAdsEnabled(docSnap.data().enabled);
      }
    });

    return () => unsubscribe(); // Cleanup listener when unmounting
  }, []);

  useEffect(() => {
    // Check user location
    const checkUserRegion = async () => {
      try {
        const response = await fetch("https://ipapi.co/country/");
        const countryCode = await response.text();
        if (countryCode === "US") {
          setIsUSUser(true);
          setShowAds(true);
        }
      } catch (error) {
        console.error("Error detecting country: ", error);
      }
    };

    checkUserRegion();
  }, []);

  useEffect(() => {
    // Handle cookie consent
    const handleAdsConsent = () => {
      const consent = localStorage.getItem("cookieConsent");
      if (adsEnabled && (isUSUser || consent === "true")) {
        setShowAds(true);
        if (!adsLoaded) {
          try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
            setAdsLoaded(true);
          } catch (error) {
            console.error("AdSense error:", error);
          }
        }
      }
    };

    window.addEventListener("adsConsentGranted", handleAdsConsent);
    handleAdsConsent(); // Run it immediately

    return () =>
      window.removeEventListener("adsConsentGranted", handleAdsConsent);
  }, [adsEnabled, adsLoaded, isUSUser]);

  if (!adsEnabled) {
    return null; // Don't render if ads are disabled
  }

  return (
    <div className="ads-container">
      {showAds ? (
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-4530213491042233"
          data-ad-slot="9624082539"
          data-ad-format="auto"
        ></ins>
      ) : (
        <div className="bg-gray-300 text-gray-600 text-center py-4 rounded">
          Ad Placeholder
        </div>
      )}
    </div>
  );
};

export default BannerAd;
