import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { db, auth } from "../utils/firebase.js"; // Firestore & Auth
import { collection, getDocs } from "firebase/firestore";
import { HeartIcon as HeartIconOutline } from "@heroicons/react/24/outline";
import { HeartIcon as HeartIconSolid } from "@heroicons/react/24/solid";
import { ChevronDownIcon } from "@heroicons/react/24/solid"; // Sorting dropdown
import BannerAd from "../components/BannerAd.js";
import LargerAd from "../components/LargerAd.js";
import {
  saveMovieToFavorites,
  removeMovieFromFavorites,
  getUserFavorites,
} from "../utils/auth.js";

const API_KEY = process.env.REACT_APP_TMDB_API_KEY;

const AllMovies = () => {
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false); // ✅ Fix: Add this line
  const [likedMovies, setLikedMovies] = useState([]);
  const [user, setUser] = useState(null);
  const [sortOption, setSortOption] = useState("release"); // Default: Sort by Release Date
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [displayedMovies, setDisplayedMovies] = useState([]);

  // Track authentication state
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isDropdownOpen && !event.target.closest(".dropdown-container")) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  // Fetch movies from Firestore & API
  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "movies"));
        let moviesList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Fetch missing poster paths, ratings, release dates, and certification
        moviesList = await Promise.all(
          moviesList.map(async (movie) => {
            if (
              !movie.poster_path ||
              !movie.release_date ||
              !movie.vote_average ||
              !movie.certification // Ensure certification is included
            ) {
              try {
                const response = await fetch(
                  `https://api.themoviedb.org/3/movie/${movie.id}?api_key=${API_KEY}&language=en-US`
                );
                const data = await response.json();

                // Fetch certification data separately
                const releaseResponse = await fetch(
                  `https://api.themoviedb.org/3/movie/${movie.id}/release_dates?api_key=${API_KEY}`
                );
                const releaseData = await releaseResponse.json();

                // Extract U.S. theatrical release certification
                const usRelease = releaseData.results?.find(
                  (release) => release.iso_3166_1 === "US"
                );
                const certification =
                  usRelease?.release_dates.find((release) => release.type === 3)
                    ?.certification || "NR"; // Default to NR if missing

                return {
                  ...movie,
                  title: movie.title || data.title || "Untitled",
                  poster_path: movie.poster_path || data.poster_path || "",
                  release_date: movie.release_date || data.release_date || "",
                  vote_average: movie.vote_average || data.vote_average || 0,
                  certification, // Store certification properly
                };
              } catch (error) {
                console.error(
                  `Error fetching details for movie ${movie.id}:`,
                  error
                );
                return { ...movie, certification: "NR" }; // Default to "NR" if an error occurs
              }
            }
            return movie;
          })
        );

        // **Filter out movies without posters**
        moviesList = moviesList.filter((movie) => movie.poster_path);

        setMovies(moviesList); // Store all movies
        setDisplayedMovies(moviesList); // Show ALL movies immediately
      } catch (error) {
        console.error("Error fetching movies from Firestore:", error);
      }
      setLoading(false);
    };

    fetchMovies();
  }, []);

  // Fetch liked movies from Firestore
  useEffect(() => {
    const fetchLikedMovies = async () => {
      if (user) {
        const likedMoviesData = await getUserFavorites(user.email);
        setLikedMovies(Array.isArray(likedMoviesData) ? likedMoviesData : []);
      }
    };

    fetchLikedMovies();
  }, [user]);

  // Handle like/unlike functionality
  const handleLike = async (movie) => {
    if (!user) {
      alert("You need to be logged in to like a movie.");
      return;
    }

    const movieId = parseInt(movie.id, 10); // ✅ Convert to integer before saving
    const isMovieLiked = likedMovies.includes(movieId);

    if (isMovieLiked) {
      await removeMovieFromFavorites(user.email, movieId);
    } else {
      await saveMovieToFavorites(user.email, movieId);
    }

    // ✅ Fetch updated favorites from Firestore to sync the UI
    const updatedFavorites = await getUserFavorites(user.email);
    setLikedMovies(updatedFavorites || []);
  };

  // Format release date
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return `${String(date.getUTCMonth() + 1).padStart(2, "0")}/${String(
      date.getUTCDate()
    ).padStart(2, "0")}/${date.getUTCFullYear()}`;
  };

  // Sort movies based on selected option
  const sortedMovies = [...movies].sort((a, b) => {
    if (sortOption === "release") {
      return new Date(b.release_date) - new Date(a.release_date);
    } else if (sortOption === "alphabetical") {
      return a.title.localeCompare(b.title);
    }
    return 0;
  });

  useEffect(() => {
    if (movies.length > 0) {
      const sortedList = [...movies].sort((a, b) => {
        if (sortOption === "release") {
          return new Date(b.release_date) - new Date(a.release_date);
        } else if (sortOption === "alphabetical") {
          return a.title.localeCompare(b.title);
        }
        return 0;
      });

      setDisplayedMovies(sortedList); // ✅ Show ALL movies immediately
    }
  }, [sortOption, movies]);

  return (
    <div className="font-custom min-h-screen bg-gray-200 dark:bg-gray-900">
      <div className="container mx-auto pt-32 px-4 flex flex-col items-center text-center">
        <h1 className="text-4xl font-bold text-gray-800 dark:text-white mb-4">
          All Movies
        </h1>
        <p className="text-lg text-gray-600 dark:text-gray-300 max-w-2xl">
          Browse all movies stored in the AfterCreds database.
        </p>

        {/* Sorting Dropdown - Stacked Below Header on Mobile */}
        <div className="relative mt-4 w-full max-w-xs sm:max-w-none sm:w-auto dropdown-container">
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="flex items-center justify-center w-full sm:w-auto px-4 py-2 text-sm font-medium bg-white dark:bg-gray-800 text-gray-800 dark:text-white border border-gray-300 dark:border-gray-600 rounded-lg shadow-md hover:bg-gray-100 dark:hover:bg-gray-700 transition duration-200"
          >
            Sort By:{" "}
            {sortOption === "release" ? "Release Date" : "Alphabetical"}
            <ChevronDownIcon className="w-5 h-5 ml-2" />
          </button>

          {isDropdownOpen && (
            <div className="absolute left-0 sm:right-0 mt-2 w-full sm:w-48 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg z-10">
              <button
                onClick={() => {
                  setSortOption("release");
                  setDisplayedMovies([]);
                  setIsDropdownOpen(false);
                }}
                className="block w-full px-4 py-2 text-left text-sm text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Release Date (Newest)
              </button>
              <button
                onClick={() => {
                  setSortOption("alphabetical");
                  setDisplayedMovies([]);
                  setIsDropdownOpen(false);
                }}
                className="block w-full px-4 py-2 text-left text-sm text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Alphabetical (A-Z)
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="container mx-auto py-16 px-4 grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-7 gap-6">
        {loading
          ? Array(14)
              .fill(0)
              .map((_, index) => (
                <div key={index} className="animate-pulse">
                  <div className="bg-gray-300 dark:bg-gray-700 h-64 rounded-lg"></div>
                  <div className="mt-2 h-4 bg-gray-300 dark:bg-gray-700 rounded w-3/4"></div>
                </div>
              ))
          : displayedMovies.map((movie, index) => (
              <React.Fragment key={movie.id}>
                <div className="relative">
                  <Link
                    to={`/movie/${movie.title.replace(/\s+/g, "-")}/${
                      movie.id
                    }`}
                  >
                    <div className="bg-gray-50 dark:bg-gray-700 rounded-lg shadow-md overflow-hidden transform hover:scale-105 hover:border-green-400 hover:border-2 transition duration-300 ease-in-out">
                      <img
                        src={
                          movie.poster_path
                            ? `https://image.tmdb.org/t/p/w500/${movie.poster_path}`
                            : "https://via.placeholder.com/500x750?text=No+Image"
                        }
                        alt={movie.title || "Unknown Movie"}
                        className="w-full h-full object-cover"
                      />
                      <div className="p-4">
                        <h3 className="text-md font-medium text-gray-800 dark:text-gray-200">
                          {movie.title.length > 23
                            ? `${movie.title.substring(0, 23)}...`
                            : movie.title}
                        </h3>
                        <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                          {movie.release_date
                            ? formatDate(movie.release_date)
                            : "N/A"}
                          {movie.certification && (
                            <span> &bull; {movie.certification}</span>
                          )}
                        </p>
                      </div>
                    </div>
                  </Link>
                  {/* Like Button */}
                  <button
                    className="absolute top-2 right-2 focus:outline-none"
                    onClick={() => handleLike(movie)}
                  >
                    {likedMovies.includes(parseInt(movie.id, 10)) ? (
                      <HeartIconSolid className="w-10 h-10 text-red-500" />
                    ) : (
                      <HeartIconOutline className="w-10 h-10 text-gray-300 dark:text-gray-500" />
                    )}
                  </button>
                </div>

                {index % 15 === 14 && <LargerAd />}
              </React.Fragment>
            ))}

        {/* Show Loading Cards when fetching more movies */}
        {loadingMore &&
          Array(10)
            .fill(0)
            .map((_, index) => (
              <div key={`loading-${index}`} className="animate-pulse">
                <div className="bg-gray-300 dark:bg-gray-700 h-64 rounded-lg"></div>
                <div className="mt-2 h-4 bg-gray-300 dark:bg-gray-700 rounded w-3/4"></div>
              </div>
            ))}
      </div>
      <BannerAd />
    </div>
  );
};

export default AllMovies;
