// src/App.js
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar.js";
import Home from "./pages/Home.js";
import MoviePage from "./pages/MoviePage.js";
import Account from "./components/Account.js";
import Favorites from "./pages/FavoritesPage.js";
import UpdatePassword from "./pages/UpdatePassword.js";
import AdminPage from "./pages/AdminPage.js";
import AboutPage from "./pages/AboutPage.js";
import PrivacyPolicy from "./pages/PrivacyPolicy.js";
import TermsOfService from "./pages/TermsOfService.js";
import CookiePolicy from "./pages/CookiePolicy.js";
import Footer from "./components/Footer.js";
import { useState } from "react";
import { db } from "./utils/firebase.js"; // Firestore instance
import { doc, setDoc } from "firebase/firestore"; // Firestore methods
import GoogleAnalytics from "./components/GoogleAnalytics.js"; // Google Analytics component
import CookieConsent from "./components/CookieConsent.js";
import AllMovies from "./pages/AllMovies.js";

function App() {
  const [searchTerm, setSearchTerm] = useState("");
  const [adsEnabled, setAdsEnabled] = useState(true); // Toggle ads

  // Function to update ad status in Firestore
  const updateAdStatus = async (newStatus) => {
    const docRef = doc(db, "settings", "ads");
    await setDoc(docRef, { enabled: newStatus }); // Update 'enabled' field in Firestore
    setAdsEnabled(newStatus); // Update local state
  };

  return (
    <Router>
      <GoogleAnalytics />
      <Navbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <CookieConsent />
      <Routes>
        <Route path="/" element={<Home searchTerm={searchTerm} />} />
        <Route path="/account" element={<Account />} />
        <Route path="/all-movies" element={<AllMovies />} />
        <Route path="/movie/:title/:movieId" element={<MoviePage />} />
        <Route path="/favorites" element={<Favorites />} />
        <Route path="/update-password" element={<UpdatePassword />} />
        <Route
          path="/admin"
          element={
            <AdminPage setAdsEnabled={updateAdStatus} adsEnabled={adsEnabled} />
          }
        />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />{" "}
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="cookie-policy" element={<CookiePolicy />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
