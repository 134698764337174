import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../components/ThemeContext.js";
import { auth } from "../utils/firebase.js";
import logo from "../images/aftercreds logo.png";
import {
  UserCircleIcon as UserCircleOutline,
  SunIcon,
  MoonIcon,
  ArrowRightStartOnRectangleIcon as LogoutOutline,
  HeartIcon as HeartOutline,
  Squares2X2Icon as SquareOutline,
  AdjustmentsHorizontalIcon as AdjustmentsHorizontalIconOutline,
  InformationCircleIcon as InformationCircleIconOutline,
} from "@heroicons/react/24/outline";
import {
  UserCircleIcon as UserCircleSolid,
  ArrowRightStartOnRectangleIcon as LogoutSolid,
  HeartIcon as HeartSolid,
  Squares2X2Icon as SquareSolid,
  AdjustmentsHorizontalIcon as AdjustmentsHorizontalIconSolid,
  InformationCircleIcon as InformationCircleIconSolid,
} from "@heroicons/react/24/solid"; // Import solid versions
import { useNavigate } from "react-router-dom";
import {
  saveUserTheme,
  getUserPreferences,
  checkIfAdmin,
} from "../utils/auth.js";

const Navbar = ({ searchTerm, setSearchTerm }) => {
  const navigate = useNavigate();
  const { isDarkMode, setIsDarkMode } = useTheme();
  const [currentUser, setCurrentUser] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false); // Track admin status
  const menuRef = useRef(null);
  const hamburgerRef = useRef(null);

  // Hover states for icons
  const [isHeartHovered, setIsHeartHovered] = useState(false);
  const [isAllMoviesHovered, setIsAllMoviesHovered] = useState(false);
  const [isAdminHovered, setIsAdminHovered] = useState(false);
  const [isAboutHovered, setIsAboutHovered] = useState(false);
  const [isProfileHovered, setIsProfileHovered] = useState(false);
  const [isLogoutHovered, setIsLogoutHovered] = useState(false);

  // Fetch user preferences when they log in
  useEffect(() => {
    const fetchPreferences = async (user) => {
      if (user) {
        const preferences = await getUserPreferences(user.email);
        const isAdminUser = await checkIfAdmin(user.email); // Fetch admin status
        setIsAdmin(isAdminUser); // Set state for admin
        if (preferences && preferences.theme) {
          setIsDarkMode(preferences.theme === "dark");
        }
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user) fetchPreferences(user);
    });
    return () => unsubscribe();
  }, [setIsDarkMode]);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate("/");
    } catch (error) {
      console.error("Logout failed: ", error);
      alert("An error occurred during logout. Please try again.");
    }
    setMenuOpen(false);
  };

  const toggleDarkMode = async () => {
    const newTheme = !isDarkMode ? "dark" : "light";
    setIsDarkMode(!isDarkMode);
    if (currentUser) {
      await saveUserTheme(currentUser.email, newTheme); // Use email here
    }
  };

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [menuOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        hamburgerRef.current &&
        !hamburgerRef.current.contains(event.target)
      ) {
        setMenuOpen(false); // Close menu when clicking outside
      }
    };

    if (menuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup listener on component unmount or when menuOpen changes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen, menuRef, hamburgerRef]);

  return (
    <nav className="bg-white dark:bg-gray-800 shadow-md fixed w-full z-20">
      <div className="container mx-auto px-4 py-4 flex items-center justify-between">
        {/* Left: App Title */}
        <Link
          to="/"
          className="flex items-center" // Helps align the image properly
          aria-label="Go to the Home page"
          onClick={() => {
            setSearchTerm("");
            setMenuOpen(false);
          }}
        >
          <img
            src={logo}
            alt="AfterCreds Logo"
            className="h-6 sm:h-7" // Adjust this height to fit the navbar, try h-8 or h-10 for better size
            style={{ width: "auto" }} // Ensures aspect ratio is preserved and adds space from the left if needed
          />
        </Link>

        {/* Center: Search Bar */}
        <div className="flex-1 mx-4">
          <input
            type="text"
            placeholder="Search movies..."
            aria-label="Search movies"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                navigate("/");
                setMenuOpen(false);
              }
            }}
            className="
      w-full py-2 px-6 rounded-lg
      text-gray-700 dark:text-gray-200
      border border-gray-300 dark:border-gray-600 
      bg-white dark:bg-gray-700 
      focus:outline-none focus:ring-4 focus:ring-green-300
      transition-all duration-300 ease-in-out shadow-md
      placeholder-gray-400
    "
          />
        </div>

        {/* Hamburger Menu for Mobile */}
        <div className="sm:hidden" ref={hamburgerRef}>
          <div
            className={`icon-menu ${menuOpen ? "open" : ""}`}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <div className="bar bar--1 bg-green-600 dark:bg-green-400"></div>
            <div className="bar bar--2 bg-green-600 dark:bg-green-400"></div>
            <div className="bar bar--3 bg-green-600 dark:bg-green-400"></div>
          </div>
        </div>

        {/* Desktop: Profile, Dark Mode Toggle, Heart, and Logout */}
        <div className="hidden sm:flex items-center space-x-4">
          <SunIcon
            className={`w-6 h-6 ${
              isDarkMode ? "text-gray-400" : "text-gray-900"
            }`}
          />
          <button
            onClick={toggleDarkMode}
            aria-label="Toggle website light or dark mode"
            className={`relative inline-flex items-center h-6 w-11 rounded-full ${
              isDarkMode ? "bg-green-600" : "bg-gray-300"
            }`}
          >
            <span
              className={`inline-block w-4 h-4 transform ${
                isDarkMode ? "translate-x-6" : "translate-x-1"
              } bg-white rounded-full transition-transform`}
            />
          </button>
          <MoonIcon
            className={`w-6 h-6 ${
              isDarkMode ? "text-gray-100" : "text-gray-400"
            }`}
          />

          {/* Heart Icon for Favorites */}
          <Link
            to={`${currentUser ? "/favorites" : "/account"}`}
            aria-label="View your favorited movies"
            className="mr-4"
          >
            {isHeartHovered ? (
              <HeartSolid
                className="w-8 h-8 text-red-500 cursor-pointer"
                onMouseLeave={() => setIsHeartHovered(false)}
              />
            ) : (
              <HeartOutline
                className={`w-8 h-8 ${
                  isDarkMode ? "text-white" : "text-black"
                } cursor-pointer`}
                onMouseEnter={() => setIsHeartHovered(true)}
              />
            )}
          </Link>

          {/* 2x2 Square Icon for All Movies */}
          <Link
            to="/all-movies"
            aria-label="Go to the all movies page"
            className="mr-4"
          >
            {isAllMoviesHovered ? (
              <SquareSolid
                className="w-8 h-8 text-green-600 cursor-pointer"
                onMouseLeave={() => setIsAllMoviesHovered(false)}
              />
            ) : (
              <SquareOutline
                className={`w-8 h-8 ${
                  isDarkMode ? "text-white" : "text-black"
                } cursor-pointer`}
                onMouseEnter={() => setIsAllMoviesHovered(true)}
              />
            )}
          </Link>

          {/* Admin Tools Icon */}
          {isAdmin && currentUser && (
            <Link
              to="/admin"
              aria-label="Go to the admin dashboard"
              className="mr-4"
            >
              {isAdminHovered ? (
                <AdjustmentsHorizontalIconSolid
                  className="w-8 h-8 text-green-600 cursor-pointer"
                  onMouseLeave={() => setIsAdminHovered(false)}
                />
              ) : (
                <AdjustmentsHorizontalIconOutline
                  className={`w-8 h-8 ${
                    isDarkMode ? "text-white" : "text-black"
                  } cursor-pointer`}
                  onMouseEnter={() => setIsAdminHovered(true)}
                />
              )}
            </Link>
          )}

          {/* About Icon */}
          <Link to="/about" aria-label="Go to the about page" className="mr-4">
            {isAboutHovered ? (
              <InformationCircleIconSolid
                className="w-8 h-8 text-green-600 cursor-pointer"
                onMouseLeave={() => setIsAboutHovered(false)}
              />
            ) : (
              <InformationCircleIconOutline
                className={`w-8 h-8 ${
                  isDarkMode ? "text-white" : "text-black"
                } cursor-pointer`}
                onMouseEnter={() => setIsAboutHovered(true)}
              />
            )}
          </Link>

          {/* Profile Icon */}
          <Link
            to="/account"
            aria-label="Go to your account settings"
            className="mr-4"
          >
            {isProfileHovered ? (
              <UserCircleSolid
                className="w-8 h-8 text-green-600 cursor-pointer"
                onMouseLeave={() => setIsProfileHovered(false)}
              />
            ) : (
              <UserCircleOutline
                className={`w-8 h-8 ${
                  isDarkMode ? "text-white" : "text-black"
                } cursor-pointer`}
                onMouseEnter={() => setIsProfileHovered(true)}
              />
            )}
          </Link>

          {currentUser && (
            <button
              onClick={handleLogout}
              aria-label="Log out"
              className="rounded"
            >
              {isLogoutHovered ? (
                <LogoutSolid
                  className="w-8 h-8 text-red-500 cursor-pointer"
                  onMouseLeave={() => setIsLogoutHovered(false)}
                />
              ) : (
                <LogoutOutline
                  className={`w-8 h-8 ${
                    isDarkMode ? "text-white" : "text-black"
                  } cursor-pointer`}
                  onMouseEnter={() => setIsLogoutHovered(true)}
                />
              )}
            </button>
          )}
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        ref={menuRef}
        className={`menu ${menuOpen ? "open" : ""} bg-white dark:bg-gray-800`}
      >
        {/* Dark Mode Toggle in Mobile Menu */}
        <div
          onClick={toggleDarkMode}
          aria-label="Toggle website light or dark mode"
          className="flex justify-around items-center my-4 p-4 w-[90%] mx-auto bg-gray-200 dark:bg-gray-700 active:bg-gray-300 dark:active:bg-gray-500 rounded"
        >
          <SunIcon className="w-6 h-6 text-gray-900 dark:text-gray-400" />
          <button
            className={`relative inline-flex items-center h-6 w-11 rounded-full ${
              isDarkMode ? "bg-green-600" : "bg-gray-300"
            }`}
          >
            <span
              className={`inline-block w-4 h-4 transform ${
                isDarkMode ? "translate-x-6" : "translate-x-1"
              } bg-white rounded-full transition-transform`}
            />
          </button>
          <MoonIcon className="w-6 h-6 text-gray-400 dark:text-gray-100" />
        </div>

        {/* Profile, Favorites, All Movies and Logout in Mobile Menu */}
        <Link
          to="/account"
          aria-label="Go to your account settings"
          className="block text-gray-800 dark:text-gray-100 mb-4 p-4 w-[90%] mx-auto bg-gray-200 dark:bg-gray-700 rounded active:bg-gray-300 dark:active:bg-gray-500 transition-all"
          onClick={() => setMenuOpen(false)} // Close menu after clicking Profile
        >
          <UserCircleOutline className="w-6 h-6 inline-block mr-2" />
          <span>Profile</span>
        </Link>
        {currentUser && (
          <div>
            <Link
              to="/favorites"
              aria-label="View your favorited movies"
              className="block text-gray-800 dark:text-gray-100 mb-4 p-4 w-[90%] mx-auto bg-gray-200 dark:bg-gray-700 rounded active:bg-gray-300 dark:active:bg-gray-500 transition-all"
              onClick={() => setMenuOpen(false)} // Close menu after clicking Profile
            >
              <HeartOutline className="w-6 h-6 inline-block mr-2" />
              <span>Favorites</span>
            </Link>
          </div>
        )}
        <Link
          to="/all-movies"
          aria-label="Browse all movies stored in the AfterCreds database."
          className="block text-gray-800 dark:text-gray-100 mb-4 p-4 w-[90%] mx-auto bg-gray-200 dark:bg-gray-700 rounded active:bg-gray-300 dark:active:bg-gray-500 transition-all"
          onClick={() => setMenuOpen(false)}
        >
          <SquareOutline className="w-6 h-6 inline-block mr-2" />
          <span>All Movies</span>
        </Link>
        {isAdmin && currentUser && (
          <Link
            to="/admin"
            aria-label="Go to the admin dashboard"
            className="block text-gray-800 dark:text-gray-100 mb-4 p-4 w-[90%] mx-auto bg-gray-200 dark:bg-gray-700 rounded active:bg-gray-300 dark:active:bg-gray-500 transition-all"
            onClick={() => setMenuOpen(false)} // Close menu after clicking Profile
          >
            <AdjustmentsHorizontalIconOutline className="w-6 h-6 inline-block mr-2" />
            <span>Admin Tools</span>
          </Link>
        )}
        <Link
          to="/about"
          aria-label="Go to the about page"
          className="block text-gray-800 dark:text-gray-100 mb-4 p-4 w-[90%] mx-auto bg-gray-200 dark:bg-gray-700 rounded active:bg-gray-300 dark:active:bg-gray-500 transition-all"
          onClick={() => setMenuOpen(false)} // Close menu after clicking Profile
        >
          <InformationCircleIconOutline className="w-6 h-6 inline-block mr-2" />
          <span>About</span>
        </Link>
        {currentUser && (
          <div>
            <button
              onClick={() => {
                handleLogout();
                navigate("/");
                setMenuOpen(false); // Close menu after logout
              }}
              aria-label="Log out"
              className="block text-red-500 p-4 w-[90%] mx-auto bg-gray-200 dark:bg-gray-700 rounded text-left active:bg-gray-300 dark:active:bg-gray-500 transition-all"
            >
              <LogoutOutline className="w-6 h-6 inline-block mr-2" />
              <span>Log Out</span>
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
